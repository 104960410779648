import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const TOGGuidesBeginnerPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_beginner.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Beginner guide</h1>
          <h2>A beginner guide for Tower of God: New World.</h2>
          <p>
            Last updated: <strong>18/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Beginner guide" />
        <Row xs={1} xxl={2}>
          <Col>
            <YoutubeEmbed embedId="DmqIvIxkO-U" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesBeginnerPage;

export const Head: React.FC = () => (
  <Seo
    title="Beginner guide | Tower of God: New World | Prydwen Institute"
    description="A beginner guide for Tower of God: New World."
    game="tog"
  />
);
